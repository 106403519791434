import {
  email,
  helpers,
  maxLength,
  minLength,
  required,
  url,
  integer,
} from '@vuelidate/validators';
import { EFormPaymentTypes } from './form.types';

const requiredCustomValidator = (value: string) => {
  return value.length > 0;
};

const validations = {
  required(message: string = 'Поле обязательно для заполнения') {
    return helpers.withMessage(message, required);
  },
  email: helpers.withMessage('Некорректный адрес электронной почты', email),
  url: helpers.withMessage('Ссылка должна содержать http:// или https://', url),
  integer: helpers.withMessage('Только цифры', integer),
  minLength(value: number) {
    return helpers.withMessage(`Поле должно содержать минимум ${value} символов`, minLength(value));
  },
  maxLength(value: number) {
    return helpers.withMessage(
      `Поле должно содержать максимум ${value} символов`,
      maxLength(value),
    );
  },
  phoneMin(value: number) {
    return helpers.withMessage('Номер должен содержать минимум 11 цифр', minLength(value));
  },
  phoneMax(value: number) {
    return helpers.withMessage('Номер должен содержать максимум 11 цифр', maxLength(value));
  },
  requiredRequisites(value: string | null) {
    const message =
      value === EFormPaymentTypes.PHONE
        ? 'Введите номер телефона для пополнения'
        : 'Введите номер карты или номер телефона, к которому привязана карта';

    const requiredRequisitesWithParams = helpers.withParams({ message }, requiredCustomValidator);

    return helpers.withMessage(({ $params }) => $params.message, requiredRequisitesWithParams);
  },
};

export default validations;
